import React, { Component } from 'react';
import './../style/welcomeText.css';
import { Button } from '@mui/material';

export class WelcomeText extends Component {
    render(): JSX.Element {
        return (
            <div className={'welcome-text-container'}>
                <p>
                    <strong>
                        Herzlich Willkommen auf der Webseite von Malermeister Heusinger aus Würzburg!{' '}
                        <br className={'break'} />
                        Wir sind ein erfahrenes und professionelles Malerunternehmen, das Ihnen hochwertige
                        Malerarbeiten und Renovierungen für Ihr Zuhause oder Geschäft bietet. <br className={'break'} />
                        Unser engagiertes Team legt großen Wert auf Qualität und Kundenzufriedenheit.{' '}
                        <br className={'break'} />
                        Wir freuen uns darauf, Ihnen bei Ihrem nächsten Projekt zu helfen und Ihnen ein schönes Zuhause
                        zu schaffen. <br className={'break'} />
                        Kontaktieren Sie uns gerne für ein unverbindliches Angebot.
                    </strong>
                </p>
                <Button
                    id={'contact-us-button'}
                    onClick={() => (window.location.href = '#contact')}
                    variant={'contained'}
                    type={'button'}
                    color={'primary'}
                >
                    Jetzt Kontakt aufnehmen
                </Button>
            </div>
        );
    }
}

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './../style/contactBulletpoints.css';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faMobileScreen } from '@fortawesome/free-solid-svg-icons/faMobileScreen';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';

const ICON_SIZE: SizeProp = '2xl';
const ICON_COLOR = '#0c54a0';

export class ContactBulletpoints extends Component {
    render(): JSX.Element {
        return (
            <div className={'contact-bulletpoints-container'}>
                <div className={'contact-bulletpoint-item'}>
                    <FontAwesomeIcon icon={faMobileScreen} color={ICON_COLOR} size={ICON_SIZE} />
                    <p>0931 66679566</p>
                </div>
                <div className={'contact-bulletpoint-item'}>
                    <FontAwesomeIcon icon={faHome} color={ICON_COLOR} size={ICON_SIZE} />
                    <p>
                        Maler Heusinger
                        <br />
                        Inh. Andreas Heusinger
                        <br />
                        An den Mühltannen 28
                        <br />
                        97080 Würzburg
                    </p>
                </div>
                <div
                    className={'contact-bulletpoint-item'}
                    style={{ cursor: 'pointer' }}
                    onClick={() => (window.location.href = 'mailto:info@maler-heusinger.de')}
                >
                    <FontAwesomeIcon icon={faEnvelope} color={ICON_COLOR} size={ICON_SIZE} />
                    <p>info@maler-heusinger.de</p>
                </div>
            </div>
        );
    }
}

import React, { Component } from 'react';
import './../style/footer.css';
import { Link } from '@mui/material';

export class Footer extends Component {
    render() {
        return (
            <div className={'footer'}>
                <Link href={'/impressum'} underline={'hover'}>
                    Impressum
                </Link>
                <p>&copy; Moritz Heusinger {new Date().getFullYear()}</p>
                <Link href={'/Datenschutz'} underline={'hover'}>
                    Datenschutz
                </Link>
            </div>
        );
    }
}

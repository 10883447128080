import React, { Component } from 'react';
import { Header } from '../components/header';
import { Footer } from '../components/footer';
import './../App.css';
import './../style/privacy.css';
import { ThemeProvider } from '@mui/material';
import { theme } from '../lib/theme';
import background from '../assets/background.jpg';

export class Privacy extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <div className="App" style={{ backgroundImage: `url(${background})` }}>
                    <div className={'content'}>
                        <Header />
                        <h2>Datenschutz</h2>
                        <div className={'content-element'} style={{ textAlign: 'left' }}>
                            <p>
                                <p style={{ fontWeight: 'bold', marginBottom: '10px' }}>Angaben Gemäß DSGVO</p>
                                Andreas Heusinger
                                <br />
                                Maler- und Lackierermeister
                                <br />
                                An den Mühltannen 28
                                <br />
                                97080 Würzburg
                                <br />
                                www.maler-heusinger.de
                            </p>

                            <ol>
                                <li>
                                    <p className={'privacy-paragraph-header'}>Gegenstand und Dauer des Auftrags</p>
                                    • Der Gegenstand und die Dauer des Auftrags müssen individuell mit dem
                                    Auftragsverarbeiter verhandelt und festgelegt werden.
                                    <br /> • Musterformulierungen sind wegen der Individualität der Vereinbarungen nicht
                                    möglich.
                                </li>
                                <li>
                                    <p className={'privacy-paragraph-header'}>
                                        Umfang, Art und Zweck der Datenverarbeitung
                                    </p>
                                    „Der Auftragnehmer verarbeitet personenbezogene Daten ausschließlich im sachlichen
                                    und zeitlichen Rahmen dieses Auftrages sowie nach Weisung des Auftraggebers. Der
                                    Auftragnehmer verwendet die zur Datenverarbeitung überlassenen Daten für keine
                                    anderen Zwecke. Kopien oder Duplikate werden ohne Wissen des Auftraggebers nicht
                                    erstellt. Die Verarbeitung der Daten auch durch Unterauftragnehmer findet
                                    ausschließlich im Gebiet der Bundesrepublik Deutschland, in einem Mitgliedstaat der
                                    Europäischen Union oder in einem anderen Vertragsstaat des Abkommens über den
                                    Europäischen Wirtschaftsraum, in einem Drittstaat statt. In letzterem Fall weist der
                                    Auftragnehmer für die Rechtmäßigkeit entsprechende vertragliche oder sonstige, der
                                    DSGVO entsprechenden Rechtsgrundlagen nach.“
                                </li>
                                <li>
                                    <p className={'privacy-paragraph-header'}>
                                        Technische und organisatorische Maßnahmen
                                    </p>
                                    „Der Auftragnehmer wird technische und organisatorische Maßnahmen zum angemessenen
                                    Schutz der Daten des Auftraggebers treffen, die den gesetzlichen Anforderungen
                                    genügen. Hierbei sind die Vertraulichkeit, Integrität, Verfügbarkeit und
                                    Belastbarkeit der Systeme und Dienste im Zusammenhang mit der Verarbeitung auf Dauer
                                    sicherzustellen. Die technisch-organisatorischen Maßnahmen des Auftragnehmers sind
                                    gesondert zu diesem Vertrag festzulegen und sind Bestandteil des Vertrags. Der
                                    Auftragnehmer gewährleistet ein Verfahren zur Überprüfung der technischen und
                                    organisatorischen Maßnahmen. Er ist verpflichtet, die technischen und
                                    organisatorischen Maßnahmen an den Stand der Technik anzupassen, soweit dies
                                    erforderlich und wirtschaftlich zumutbar ist. Der Auftraggeber ist über wesentliche
                                    Änderungen vorab zu informieren. Die Änderungen sind schriftlich niederzulegen und
                                    werden Vertragsbestandteil. Vorschläge des Auftraggebers für Änderungen hat der
                                    Auftragnehmer zu prüfen. Der Auftraggeber ist über das Ergebnis zu informieren.
                                    <br /> Beauftragt der Auftragnehmer zur Erfüllung seiner vertraglichen Pflichten
                                    einen Unterauftragnehmer, stellt er sicher, dass die erforderlichen technischen und
                                    organisatorischen Maßnahmen vom Unterauftragnehmer getroffen werden und dem Stand
                                    der Technik entsprechen.“
                                </li>
                                <li>
                                    <p className={'privacy-paragraph-header'}>
                                        Berichtigung, Sperrung und Löschung von Daten, Auskunft über Daten
                                    </p>
                                    „Der Auftragnehmer hat die Daten nach Weisung des Auftraggebers zu berichtigen, zu
                                    löschen oder zu sperren. Soweit ein Betroffener sich unmittelbar an den
                                    Auftragnehmer zwecks Berichtigung, Sperrung oder Löschung seiner Daten wendet,
                                    leitet der Auftragnehmer dieses Ersuchen unverzüglich an den Auftraggeber weiter.
                                    Das gleiche gilt für Auskunftsersuche.“
                                </li>
                                <li>
                                    <p className={'privacy-paragraph-header'}>
                                        Kontrollen und sonstige Pflichten des Auftragnehmers
                                    </p>
                                    „Der Auftragnehmer ist verpflichtet, das Datengeheimnis sowie etwaige berufliche
                                    Verschwiegenheitsverpflichtungen zu wahren. Er hat bei der Verarbeitung
                                    ausschließlich Beschäftigte einzusetzen, die entsprechend verpflichtet und geschult
                                    sind. Er hat insbesondere sicherzustellen, dass alle Personen, die von ihm mit der
                                    <br /> Bearbeitung oder Erfüllung dieses Vertrages betraut sind, sorgfältig
                                    ausgewählt werden, die gesetzlichen Datenschutzbestimmungen beachten und die vom
                                    Auftraggeber erlangten Informationen nicht unbefugt an Dritte weitergeben oder
                                    anderweitig verwerten.
                                    <br /> Der Auftragnehmer nennt dem Auftraggeber den Ansprechpartner für sämtliche
                                    vertragsrelevanten Angelegenheiten des Datenschutzes. Der Auftragnehmer hat Herrn
                                    Andreas Heusinger als betrieblichen Datenschutzbeauftragten bestellt. Der
                                    Auftragnehmer ist verpflichtet, ein Verarbeitungsverzeichnis gemäß Art. 30 Abs.
                                    2DSGVO zu führen. Der Auftragnehmer gewährt dem Landesdatenschutzbeauftragten
                                    <br /> Zugang zu den Arbeitsräumen und unterwirft sich der Kontrolle nach Maßgabe
                                    des Landesdatenschutzgesetzes in seiner jeweiligen Fassung. Der Auftragnehmer
                                    informiert den Auftraggeber unverzüglich über Kontroll- und Ermittlungshandlungen
                                    der Aufsichtsbehörde.“
                                </li>
                                <li>
                                    <p className={'privacy-paragraph-header'}>Unterauftragsverhältnisse</p>
                                    „Der Auftraggeber genehmigt die gesondert aufgelisteten Unterauftragsverhältnisse,
                                    die der Auftragnehmer vor Abschluss dieser Vereinbarung begründet hat. Über
                                    Änderungen hat der Auftragnehmer den Auftraggeber unverzüglich zu informieren Der
                                    Abschluss neuer Unterauftragsverhältnisse bedarf der vorherigen Zustimmung des
                                    Auftraggebers. Der Auftragnehmer hat dem Unterauftragnehmer dieselben Pflichten
                                    aufzuerlegen,
                                    <br /> die er selbst gegenüber dem Auftraggeber zu erfüllen hat. Der
                                    Unterauftragnehmer ist sorgfältig auszuwählen. Der Auftragnehmer haftet gegenüber
                                    dem Auftraggeber vollumfänglich für Datenverstöße seiner Unterauftragnehmer.“
                                </li>
                                <li>
                                    <p className={'privacy-paragraph-header'}>Kontrollrechte des Auftraggebers</p>
                                    „Der Auftraggeber hat das Recht, vor Beginn und während der Datenverarbeitung die
                                    Einhaltung der vom Auftragnehmer sowie von den Unterauftragnehmern getroffenen
                                    technischen und organisatorischen Maßnahmen zu kontrollieren oder von zu benennenden
                                    Prüfern kontrollieren zu lassen. Das Ergebnis ist zu dokumentieren. Der
                                    Auftragnehmer gewährleistet die Möglichkeit zur Kontrolle. Hierzu weist er dem
                                    Auftraggeber auf Anfrage die Umsetzung der technischen und organisatorischen
                                    Maßnahmen gemäß Art. 32 DSGVO nach. Der Nachweis kann durch Vorlage aktueller
                                    Testats oder durch Berichte unabhängiger Prüfer (z.B. Wirtschaftsprüfer, Revision,
                                    Datenschutzbeauftragter, Datenschutzauditoren, Qualitätsauditoren) erbracht werden.
                                    <br /> Haben sich der Auftragnehmer und die von ihm beauftragten Unterauftragnehmer
                                    Verhaltensregeln unterworfen oder ein Zertifizierungsverfahren erfolgreich
                                    durchlaufen, sind sie verpflichtet, dem Auftraggeber dies nachzuweisen. Zertifikate
                                    sind zu aktualisieren.
                                    <br /> Der Auftraggeber ist berechtigt, Stichprobenkontrollen durchzuführen. Diese
                                    sind anzukündigen. Würde die Ankündigung den Zweck der Prüfung gefährden oder
                                    besteht ein dringender Anlass zur Kontrolle, ist eine Ankündigung entbehrlich.“
                                </li>
                                <li>
                                    <p className={'privacy-paragraph-header'}>Mitteilung bei Verstößen</p>
                                    „Der Auftragnehmer meldet dem Auftraggeber unverzüglich sämtliche Verstöße
                                    <br /> gegen Pflichten aus diesem Vertrag. Dies gilt insbesondere bei
                                    schwerwiegenden Störungen des Betriebsablaufs, bei Verdacht auf sonstige
                                    Verletzungen von Vorschriften zum Schutz personenbezogener Daten oder anderen
                                    Unregelmäßigkeiten beim Umgang mit personenbezogenen Daten. Der Auftragnehmer hat im
                                    Benehmen mit dem Auftraggeber angemessene Maßnahmen zur Sicherung der Daten sowie
                                    zur Minderung bzw. zum Ausschluss möglicher nachteiliger Folgen für die Betroffenen
                                    zu ergreifen.“
                                </li>
                                <li>
                                    <p className={'privacy-paragraph-header'}>Weisungsbefugnis des Auftraggebers</p>
                                    „Der Auftraggeber ist berechtigt, dem Auftragnehmer jederzeit Weisungen zu erteilen,
                                    insbesondere hinsichtlich der Art, des Umfangs und des Zeitpunkts der Verarbeitung
                                    von Daten. Die Weisungen des Auftraggebers erfolgen in Textform.
                                    <br /> Erachtet der Auftragnehmer eine Weisung des Auftraggebers als rechtswidrig,
                                    hat er den Auftraggeber unverzüglich darauf hinzuweisen. Er ist berechtigt, die
                                    Durchführung der Weisung auszusetzen, bis sie durch den Auftraggeber bestätigt oder
                                    geändert wird.
                                    <br />
                                    Erteilt der Auftraggeber Einzelweisungen bzgl. des Umgangs mit personenbezogenen
                                    Daten, die über den vertraglich vereinbarten Leistungsumfang hinausgehen, z.B
                                    Änderungen der technischen und organisatorischen Maßnahmen, werden sie als Antrag
                                    auf Leistungsänderung behandelt.“
                                </li>
                                <li>
                                    <p className={'privacy-paragraph-header'}>
                                        Löschung von Daten und Rückgabe von Datenträgern
                                    </p>
                                    „Der Auftragnehmer hat dem Auftraggeber sämtliche in seinen Besitz befindlichen
                                    personenbezogenen Daten, erstellte Verarbeitungsergebnisse sowie Datenbestände, die
                                    im Zusammenhang mit dem Auftragsverhältnis stehen, unverzüglich nach Erfüllung des
                                    Vertrags oder nach Aufforderung durch den Auftraggeber, spätestens mit Beendigung
                                    der Zusammenarbeit auszuhändigen oder nach vorheriger Zustimmung des Auftraggebers
                                    datenschutzgerecht zu vernichten. Das Protokoll der Löschung ist auf Anforderung
                                    vorzulegen. Ein Zurückbehaltungsrecht ist ausgeschlossen. Dokumentationen, die dem
                                    Nachweis der auftrags- und ordnungsgemäßen Datenverarbeitung dienen, sind vom
                                    Auftragnehmer entsprechend der geltenden Aufbewahrungsfristen über das Vertragsende
                                    hinaus aufzubewahren.“
                                </li>
                            </ol>
                        </div>
                        <Footer />
                    </div>
                </div>
            </ThemeProvider>
        );
    }
}

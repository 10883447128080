import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import './../style/iconBulletpoints.css';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

interface IconBulletpointsProps {
    icon1: IconDefinition;
    header1: string;
    text1: string;
    icon2: IconDefinition;
    header2: string;
    text2: string;
    icon3: IconDefinition;
    header3: string;
    text3: string;
    /** if border should be added to icons */
    withBorder?: boolean;
}

const ICON_SIZE: SizeProp = '2xl';
const ICON_COLOR = '#0c54a0';

export class IconBulletpoints extends Component<IconBulletpointsProps> {
    render() {
        return (
            <div className={'bulletpoints-container'}>
                <div className={'bulletpoint-item'}>
                    <FontAwesomeIcon
                        icon={this.props.icon1}
                        color={ICON_COLOR}
                        size={ICON_SIZE}
                        style={
                            this.props.withBorder ? { border: '1px solid', borderRadius: '10px', padding: '10px' } : {}
                        }
                    />
                    <h3>{this.props.header1}</h3>
                    <p>{this.props.text1}</p>
                </div>
                <div className={'bulletpoint-item'}>
                    <FontAwesomeIcon
                        icon={this.props.icon2}
                        color={ICON_COLOR}
                        size={ICON_SIZE}
                        style={
                            this.props.withBorder ? { border: '1px solid', borderRadius: '10px', padding: '10px' } : {}
                        }
                    />
                    <h3>{this.props.header2}</h3>
                    <p>{this.props.text2}</p>
                </div>
                <div className={'bulletpoint-item'}>
                    <FontAwesomeIcon
                        icon={this.props.icon3}
                        color={ICON_COLOR}
                        size={ICON_SIZE}
                        style={
                            this.props.withBorder ? { border: '1px solid', borderRadius: '10px', padding: '10px' } : {}
                        }
                    />
                    <h3>{this.props.header3}</h3>
                    <p>{this.props.text3}</p>
                </div>
            </div>
        );
    }
}

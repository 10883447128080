import React, { Component } from 'react';
import './App.css';
import { ContactUs } from './components/contact';
import { Footer } from './components/footer';
import { Header } from './components/header';
import { faPaintRoller } from '@fortawesome/free-solid-svg-icons/faPaintRoller';
import { faScrewdriver } from '@fortawesome/free-solid-svg-icons/faScrewdriver';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { IconBulletpoints } from './components/iconBulletpoints';
import { faLeaf } from '@fortawesome/free-solid-svg-icons/faLeaf';
import { faPalette } from '@fortawesome/free-solid-svg-icons/faPalette';
import { faShield } from '@fortawesome/free-solid-svg-icons/faShield';
import { ImageGallery } from './components/imageGallery';
import background from './assets/background.jpg';
import { ThemeProvider } from '@mui/material';
import { theme } from './lib/theme';
import { ContactBulletpoints } from './components/contactBulletpoints';
import { SocialMediaBulletpoints } from './components/socialMediaBulletpoints';
import { WelcomeText } from './components/welcomeText';
import { ContactSubmitted } from './components/contactSubmitted';

interface AppState {
    formSent: boolean;
}

export default class App extends Component<Record<string, any>, AppState> {
    constructor(props: Record<string, any>) {
        super(props);

        this.state = {
            formSent: false
        };
    }
    render() {
        return (
            <ThemeProvider theme={theme}>
                <div className="App" style={{ backgroundImage: `url(${background})` }}>
                    <div className={'content'}>
                        <Header />
                        <div className={'content-element'}>
                            <WelcomeText />
                        </div>
                        <div className={'content-element'}>
                            <h2>Leistungen</h2>
                            <IconBulletpoints
                                icon1={faPaintRoller}
                                header1="Malerservice"
                                text1={this.getTextMalerservice()}
                                icon2={faScrewdriver}
                                header2="Trockenausbau"
                                text2={this.getTextTrockenausbau()}
                                icon3={faHome}
                                header3="Putzarbeiten"
                                text3={this.getTextPutzarbeiten()}
                                withBorder={true}
                            />

                            <IconBulletpoints
                                icon1={faLeaf}
                                header1="Energetische Sanierung"
                                text1={this.getTextEnergetischeSanierung()}
                                icon2={faPalette}
                                header2="Gestaltung"
                                text2={this.getTextGestaltung()}
                                icon3={faShield}
                                header3="Schimmelsanierung"
                                text3={this.getTextSchimmelsanierung()}
                                withBorder={true}
                            />
                        </div>
                        <div className={'content-element'}>
                            <h2>Bildergalerie</h2>
                            <ImageGallery />
                        </div>
                        <div id="contact" className={'content-element'}>
                            <h2>Kontaktformular</h2>
                            {!this.state.formSent ? (
                                <ContactUs onSent={() => this.setState({ formSent: true })} />
                            ) : (
                                <ContactSubmitted />
                            )}
                        </div>
                        <div className={'content-element-row'}>
                            <div className={'content-element'}>
                                <ContactBulletpoints />
                            </div>
                            <div className={'content-element'}>
                                <SocialMediaBulletpoints />
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </ThemeProvider>
        );
    }

    getTextMalerservice(): string {
        return `Unser Malerservice bietet Ihnen eine breite Palette an Dienstleistungen im Bereich Malerarbeiten und
                Renovierungen. Ob Sie eine neue Farbe an den Wänden möchten, Tapezierarbeiten benötigen oder
                Lackierungen durchführen lassen möchten - wir stehen Ihnen mit unserer Erfahrung und Expertise zur
                Seite. Wir setzen auf hochwertige Materialien und moderne Technologien, um ein optimales Ergebnis zu
                erzielen. Kontaktieren Sie uns für ein unverbindliches Angebot und lassen Sie uns gemeinsam Ihre Wohn-
                oder Geschäftsräume in neuem Glanz erstrahlen.`;
    }

    getTextTrockenausbau(): string {
        return `Beim Trockenausbau werden Wände, Decken oder Böden aus Trockenbauelementen errichtet. Diese ermöglichen
                eine schnelle und flexible Gestaltung Ihrer Räumlichkeiten. Der Trockenausbau bietet zahlreiche
                Vorteile, wie beispielsweise eine hohe Energieeffizienz oder Schallschutz. Wir planen und realisieren
                Ihren Trockenausbau individuell und nach Ihren Wünschen. Dabei setzen wir auf hochwertige Materialien
                und moderne Technologien, um ein optimales Ergebnis zu erzielen. Kontaktieren Sie uns für eine
                persönliche Beratung und lassen Sie uns gemeinsam Ihre Wohn- oder Geschäftsräume funktional und modern
                gestalten.`;
    }
    getTextPutzarbeiten(): string {
        return `Unser Putz-Service bietet Ihnen eine breite Palette an Leistungen im Innen- und Außenbereich, um Ihre
                Räumlichkeiten individuell und funktional zu gestalten. Wir sind spezialisiert auf verschiedene
                Putzarten wie Glattputz, Strukturputz, Fassadenputz oder Sanierputz und setzen auf hochwertige
                Materialien und moderne Technologien, um ein perfektes Ergebnis zu erzielen. Auch kleinere
                Ausbesserungsarbeiten oder Reparaturen führen wir zuverlässig und professionell durch. Kontaktieren Sie
                uns für eine unverbindliche Beratung und lassen Sie uns gemeinsam Ihre Wohn- oder Geschäftsräume in
                neuem Glanz erstrahlen.`;
    }
    getTextEnergetischeSanierung(): string {
        return `Eine energetische Sanierung beinhaltet Maßnahmen zur Verbesserung der Energieeffizienz eines Gebäudes,
                um den Energiebedarf zu reduzieren und die Umwelt zu schonen. Dazu gehören unter anderem Dämmung von
                Dach und Fassade, Erneuerung von Fenstern und Türen sowie Optimierung der Heizungsanlage. Wir setzen auf
                qualitativ hochwertige Materialien und modernste Technologien, um ein optimales Ergebnis zu erzielen.
                Eine energetische Sanierung ist nicht nur umweltfreundlich, sondern spart auf lange Sicht auch Kosten
                durch eine Reduktion des Energieverbrauchs.`;
    }

    getTextGestaltung(): string {
        return `Unser Unternehmen ist Ihr kompetenter Partner für die individuelle Gestaltung Ihrer Räumlichkeiten. Wir
                bieten Ihnen eine breite Palette an kreativen Ideen und Techniken, um Ihren Wohnraum oder
                Geschäftsbereich in ein einzigartiges Ambiente zu verwandeln. Ob Wandgestaltung, Tapezieren oder
                Farbkonzepte - wir setzen Ihre Wünsche professionell und zuverlässig um. Dabei achten wir stets auf
                höchste Qualität und eine sorgfältige Ausführung. Kontaktieren Sie uns für eine persönliche Beratung und
                gemeinsame Planung Ihrer individuellen Gestaltungswünsche.`;
    }

    getTextSchimmelsanierung(): string {
        return `Schimmel in Wohn- oder Arbeitsräumen kann ein ernsthaftes Gesundheitsrisiko darstellen und sollte daher
                schnellstmöglich beseitigt werden. Als erfahrener Malermeister sind wir spezialisiert auf die
                professionelle Schimmelsanierung. Wir identifizieren die Ursachen für Schimmelbildung und beseitigen
                diese mit geeigneten Maßnahmen. Dabei setzen wir auf umweltfreundliche und schonende Methoden, um ein
                optimales Ergebnis zu erzielen. Kontaktieren Sie uns für eine unverbindliche Beratung und gemeinsame
                Planung einer schnellen und effektiven Schimmelsanierung.`;
    }
}

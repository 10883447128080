import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Privacy } from './routes/privacy';
import { Impress } from './routes/impress';
import './assets/fonts/DIN-Next-LT-Pro-Regular.ttf';
import './assets/fonts/DINNextLTPro-Bold.ttf';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const router = createBrowserRouter([
    {
        path: '/',
        element: <App />
    },
    {
        path: '/datenschutz',
        element: <Privacy />
    },
    {
        path: '/impressum',
        element: <Impress />
    }
]);

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { Component } from 'react';
import { Header } from '../components/header';
import { Footer } from '../components/footer';
import './../App.css';
import { ThemeProvider } from '@mui/material';
import { theme } from '../lib/theme';
import background from '../assets/background.jpg';

export class Impress extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <div className="App" style={{ backgroundImage: `url(${background})` }}>
                    <div className={'content'}>
                        <Header />
                        <h2>Impressum</h2>
                        <div className={'content-element'}>
                            <p>
                                Andreas Heusinger
                                <br />
                                Maler- und Lackierermeister
                                <br /> An den Mühltannen 28
                                <br /> 97080 Würzburg
                                <br /> www.maler-heusinger.de
                            </p>
                        </div>
                        <Footer />
                    </div>
                </div>
            </ThemeProvider>
        );
    }
}

import React, { Component } from 'react';
import logo from '../assets/logo.png';
import './../style/header.css';

export class Header extends Component {
    render() {
        return (
            <div>
                <a href={'/'}>
                    <img src={logo} className={'header-image'} />
                </a>
                <h1>Maler Heusinger aus Würzburg</h1>
                <hr className={'header-divider'} />
                <h3>Ihr Maler- und Lackierer-Meisterbetrieb</h3>
            </div>
        );
    }
}

import React, { Component } from 'react';
import { ImageList, ImageListItem } from '@mui/material';

interface ImageObj {
    img: any;
    title: string;
}

const imageData: ImageObj[] = [
    {
        img: require('./../assets/gallery/IMG_1005.JPEG'),
        title: 'Kalkputz'
    },
    {
        img: require('./../assets/gallery/IMG_1153.JPEG'),
        title: 'Tapezierarbeiten'
    },
    {
        img: require('./../assets/gallery/IMG_1488.JPEG'),
        title: 'Spachtelarbeiten'
    },
    {
        img: require('./../assets/gallery/IMG_3050.jpg'),
        title: 'Energetische Sanierung'
    },
    {
        img: require('./../assets/gallery/IMG_3071.jpg'),
        title: 'Aufzugportal'
    },
    {
        img: require('./../assets/gallery/IMG_3074.JPEG'),
        title: 'Sanierung'
    },
    {
        img: require('./../assets/gallery/IMG_3075.JPEG'),
        title: 'Gestaltung'
    },
    {
        img: require('./../assets/gallery/IMG_3076.JPEG'),
        title: 'Trockenausbau'
    },
    {
        img: require('./../assets/gallery/IMG_3085.JPEG'),
        title: 'Fachwerkinstandsetzung, Aussicht'
    },
    {
        img: require('./../assets/gallery/IMG_3086.jpg'),
        title: 'Schattenfuge, Akustikputz, Trockenbau'
    },
    {
        img: require('./../assets/gallery/IMG_4029.JPEG'),
        title: 'Energetische Sanierung, Reparatur'
    },
    {
        img: require('./../assets/gallery/IMG_5572.JPEG'),
        title: 'Schimmelsanierung'
    },
    {
        img: require('./../assets/gallery/IMG_0884.JPG'),
        title: 'Rostoptik'
    },
    {
        img: require('./../assets/gallery/IMG_2541.JPEG'),
        title: 'Fahrzeug'
    },
    {
        img: require('./../assets/gallery/IMG_3340.JPEG'),
        title: 'Energetische Sanierung, Rundung'
    },
    {
        img: require('./../assets/gallery/IMG_3424.jpg'),
        title: 'Corporate Identity'
    },
    {
        img: require('./../assets/gallery/IMG_3425.jpg'),
        title: 'Lackierung'
    },
    {
        img: require('./../assets/gallery/IMG_3426.jpg'),
        title: 'Fassadengestaltung, Reparatur'
    },
    {
        img: require('./../assets/gallery/IMG_3599.JPEG'),
        title: 'Reparaturarbeiten, Staubfrei'
    },
    {
        img: require('./../assets/gallery/IMG_7632.JPEG'),
        title: 'Malerarbeiten, Gestaltung'
    },
    {
        img: require('./../assets/gallery/IMG_3012.jpeg'),
        title: 'Materialmix'
    }
];

export class ImageGallery extends Component {
    render(): JSX.Element {
        return (
            <ImageList sx={{ width: '100%', height: 800 }} cols={3}>
                {imageData.map(item => (
                    <ImageListItem key={item.img}>
                        <img src={item.img} alt={item.title} loading={'lazy'} />
                    </ImageListItem>
                ))}
            </ImageList>
        );
    }
}

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import './../style/contactSubmitted.css';

export class ContactSubmitted extends Component {
    render() {
        return (
            <div className={'contact-submitted-container'}>
                <FontAwesomeIcon icon={faCheckCircle} size={'7x'} color={'green'} />
                <p className={'contact-submitted-success-text'}>Vielen Dank für Ihre Nachricht!</p>
            </div>
        );
    }
}

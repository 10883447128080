import { createTheme } from '@mui/material/styles';
import { blue } from '@mui/material/colors';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#0c54a0'
        },
        secondary: {
            main: blue[500]
        }
    }
});

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './../style/socialMediaBulletpoints.css';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';

const ICON_SIZE: SizeProp = '5x';
const ICON_COLOR = '#0c54a0';

export class SocialMediaBulletpoints extends Component {
    render(): JSX.Element {
        return (
            <div className={'social-bulletpoints-container'}>
                <div className={'social-bulletpoint-item'}>
                    <FontAwesomeIcon
                        icon={faInstagram as any}
                        color={ICON_COLOR}
                        size={ICON_SIZE}
                        className={'social-icon'}
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                            (window.location.href = 'https://instagram.com/maler_heusinger?igshid=YmMyMTA2M2Y=')
                        }
                    />
                </div>
                <div className={'social-bulletpoint-item'}>
                    <FontAwesomeIcon
                        icon={faFacebook as any}
                        color={ICON_COLOR}
                        size={ICON_SIZE}
                        className={'social-icon'}
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                            (window.location.href = 'https://www.facebook.com/MalerHeusingerMeisterbetrieb/')
                        }
                    />
                </div>
            </div>
        );
    }
}
